























































import { Component, Prop, Ref, Vue } from "vue-property-decorator"
import Client from "@/models/Client"
import Address from "@/models/Address"
import StringTool from "@/services/tool/StringTool"
import Dialog from "@/models/vue/Dialog"
import AddressService from "@/services/AddressService"
import DialogModule from "@/store/DialogModule"
import { getModule } from "vuex-module-decorators"
import LangModule from "@/store/LangModule"

@Component
export default class AddressDialog extends Vue {
	@Ref() readonly form!: HTMLFormElement
	@Prop() readonly client!: Client
	@Prop() readonly dialog!: boolean
	address: Address = new Address()
	loading: boolean = false
	lang: any = getModule(LangModule).lang

	/*    R U L E S   */
	minLength = 4
	maxLength = 128
	basicRules = [
		(input: string) => (input ? true : this.lang.emptyLabel),
		(input: string) => (input?.length > this.minLength ? true : StringTool.replaceDynText(this.lang.minimumLength, "%numberSlot%", this.minLength.toString())),
		(input: string) => (input?.length < this.maxLength ? true : StringTool.replaceDynText(this.lang.maximusLength, "%numberSlot%", this.maxLength.toString())),
	]
	emailRules = [
		(input: string) => StringTool.validateEmail(input) ? true : this.lang.invalidEmail
	]
	postalCodeRules = [
		(input: string) => StringTool.validatePostalCode(input) ? true : this.lang.invalidPostalCode,
	]

	created() {
	}
	
	createAddress() {
		if (this.form.validate()) {
			getModule(DialogModule).showDialog(
				new Dialog(this.lang.warning, this.lang.createAddressConfirmation, () =>
					AddressService.postAddress(this, this.address, this.client.id!, false).finally(() => { 
						this.$emit('refresh') 
						this.$emit('close')
					})
				)
			)
		}
	}
}
